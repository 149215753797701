import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const AboutOne = React.lazy(() => import("../components/AboutOne"));
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const CounterOne = React.lazy(() => import("../components/CounterOne"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const PartnerOne = React.lazy(() => import("../components/PartnerOne"));

const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>

          <SearchPopup />

          <NavbarTwo />

          <Breadcrumb title={"NOSOTROS"} />

          <div className='pd-top-120 pd-bottom-120'>
            <AboutOne />
            <AboutTwo />
          </div>

          <VideoAreaOne />

          <PartnerOne />

          <FooterOne />

          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;

import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const PortfolioLogistics = React.lazy(() => import("../components/PortfolioLogistics"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const ServiceLogisticInner = React.lazy(() =>
  import("../components/ServiceLogisticInner")
);
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const ServiceLogistic = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <SearchPopup />
          <NavbarTwo />
          <Breadcrumb title={"TRANSPORTE & LOGISTICA"} />
          <ServiceLogisticInner />
          <PortfolioLogistics />
          <FooterOne />
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default ServiceLogistic;